import axios from "axios";

const proxyUrl = "https://cors-anywhere.herokuapp.com/";
const apiUrl = "https://viatorapi.viator.com/service/";
const apiKey = "2721862758045286349";

export const RequestHelper = {
  get: (path, params) => {
    let options = { params: { apiKey: apiKey, ...params } };
    return axios
      .get(proxyUrl + apiUrl + path, options)
      .then(function(response) {
        console.log(response);
        return response.data.data
          ? response.data.data
          : {
              errorCodes: response.errorCodes,
              errorMessage: response.errorMessage,
              errorMessageText: response.errorMessageText
            };
      })
      .catch(function(error) {
        console.log(error);
        return error.data;
      });
  },

  post: (path, data) => {
    return axios
      .post(proxyUrl + apiUrl + path + "?apiKey=" + apiKey, data)
      .then(function(response) {
        console.log(response);
        return response.data.data
          ? response.data.data
          : {
              errorCodes: response.data.errorCodes,
              errorMessage: response.data.errorMessage,
              errorMessageText: response.data.errorMessageText
            };
      })
      .catch(function(error) {
        console.log(error);
        return error.data;
      });
  }
};
