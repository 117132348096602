import React, { Component } from "react";
import { UtilityServices } from "../../api/UtilityServices";
import RequestComponent from "../../../components/RequestComponent";

class DetermineCountryByIPAddress extends Component {
  submitText = "getDetermineCountryByIPAddress";
  parameters = [{ paramKey: "ip", paramVal: "61.69.86.126" }];
  doRequest = paramStates => {
    return UtilityServices.getDetermineCountryByIPAddress(paramStates.ip).then(
      res => {
        return { data: res };
      }
    );
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default DetermineCountryByIPAddress;
