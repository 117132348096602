import React, { Component } from "react";
import { UtilityServices } from "../../api/UtilityServices";
import RequestComponent from "../../../components/RequestComponent";

class SiteDetails extends Component {
  submitText = "getSiteDetails";
  parameters = [
    { paramKey: "defaultCurrencyCode", paramVal: "USD" },
    { paramKey: "centralURL", paramVal: "alllasvegastours.com" }
  ];

  doRequest = paramStates => {
    return UtilityServices.getSiteDetails(
      paramStates.defaultCurrencyCode,
      paramStates.centralURL
    ); //.then(res => res.data);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default SiteDetails;
