import React, { Component } from "react";
import { ProductServices } from "../../api/ProductServices";
import RequestComponent from "../../../components/RequestComponent";

class SearchForProducts extends Component {
  submitText = "searchForProducts";
  parameters = [
    { paramKey: "destId", paramVal: "479" },
    { paramKey: "catId", paramVal: "4" },
    { paramKey: "subCatId", paramVal: "11" },
    { paramKey: "seoId", paramVal: "" },
    { paramKey: "currencyCode", paramVal: "USD" },
    { paramKey: "dealsOnly", paramVal: "false" },
    { paramKey: "startDate", paramVal: "2019-01-01" },
    { paramKey: "endDate", paramVal: "2020-01-01" },
    { paramKey: "topX", paramVal: "1-5" },
    { paramKey: "sortOrder", paramVal: "REVIEW_AVG_RATING_D" }
  ];

  doRequest = paramStates => {
    return ProductServices.searchForProducts(
      paramStates.destId, // destinationId is available from the /taxonomy/destinations service. Use EITHER destId OR seoId, but not both
      paramStates.catId, // categoryId can be retrieved from the /taxonomy/categories service. At present, it is not possible to use catId in conjunction with seoId
      paramStates.subCatId, // subcategoryId can be retrieved from the /taxonomy/categories service. At present, it is not possible to use subCatId in conjunction with seoId
      paramStates.seoId, // use EITHER destId OR seoId, but not both
      (paramStates.currencyCode = "USD"),
      paramStates.dealsOnly, //  set this field to true only when there is no startDate or endDate,
      paramStates.startDate, // = "2020-01-01",
      paramStates.endDate, // = "2019-08-01",
      (paramStates.topX = "1-10"),
      paramStates.sortOrder
    ); //.then(res => res.data);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default SearchForProducts;
