import { RequestHelper } from "./RequestHelper";

export const UtilityServices = {
  getDetermineCountryByIPAddress: ip =>
    RequestHelper.get("util/ip2country", {
      ip: ip
    })
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
        return error;
      }),

  getSiteDetails: (defaultCurrencyCode, centralURL) =>
    RequestHelper.get("util/sitedetails", {
      defaultCurrencyCode,
      centralURL
    })
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
        return error;
      })
};
