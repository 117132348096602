import { RequestHelper } from "./RequestHelper";

export const ProductServices = {
  searchForProducts: (
    destId, // destinationId is available from the /taxonomy/destinations service. Use EITHER destId OR seoId, but not both
    catId, // categoryId can be retrieved from the /taxonomy/categories service. At present, it is not possible to use catId in conjunction with seoId
    subCatId, // subcategoryId can be retrieved from the /taxonomy/categories service. At present, it is not possible to use subCatId in conjunction with seoId
    seoId, // use EITHER destId OR seoId, but not both
    currencyCode = "USD",
    dealsOnly, //  set this field to true only when there is no startDate or endDate,
    startDate, // = "2020-01-01",
    endDate, // = "2019-08-01",
    topX = "1-10",
    sortOrder = "REVIEW_AVG_RATING_A" // Enum:"TOP_SELLERS","REVIEW_AVG_RATING_A","REVIEW_AVG_RATING_D","PRICE_FROM_A","PRICE_FROM_D"
  ) =>
    RequestHelper.post("search/products", {
      destId,
      catId,
      subCatId,
      seoId,
      currencyCode,
      dealsOnly,
      startDate,
      endDate,
      topX,
      sortOrder
    })
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
        return error;
      }),

  searchByProductCode: (
    productCodes, // Array
    currencyCode = "USD"
  ) =>
    RequestHelper.post("search/products/codes", {
      productCodes,
      currencyCode
    })
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
        return error;
      }),

  freeTextSearch: (
    destId = "479",
    text,
    searchTypes = "PRODUCT", // Enum:"PRODUCT" "DESTINATION" "ATTRACTION" "RECOMMENDATION",
    currencyCode = "USD",
    sortOrder = "REVIEW_AVG_RATING_D",
    topX = "1-10"
  ) =>
    RequestHelper.post("search/freetext", {
      destId,
      text,
      searchTypes: [searchTypes],
      currencyCode,
      sortOrder,
      topX
    })
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
        return error;
      })
};
