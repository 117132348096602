import React from "react";
import UtilityServiceComponent from "./viator/components/UtilityServiceComponent";
import TaxonomyServicesComponent from "./viator/components/TaxonomyServicesComponent";
import ProductServiceComponent from "./viator/components/ProductServiceComponent";

import "./App.css";

function App() {
  return (
    <div className="App">
      <p />
      <UtilityServiceComponent />
      <TaxonomyServicesComponent />
      <ProductServiceComponent />
    </div>
  );
}

export default App;
