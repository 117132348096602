import React, { Component } from "react";
import DetermineCountryByIPAddress from "./DetermineCountryByIPAddress";
import SiteDetails from "./SiteDetails";

class UtilityServiceComponent extends Component {
  render() {
    return (
      <>
        <DetermineCountryByIPAddress />
        <SiteDetails />
      </>
    );
  }
}

export default UtilityServiceComponent;
