import { RequestHelper } from "./RequestHelper";

export const TaxonomyServices = {
  getDestinations: () =>
    RequestHelper.get("taxonomy/destinations")
      .then(function(response) {
        console.log(response);
        return response.sort((a, b) =>
          a.destinationType > b.destinationType
            ? 1
            : b.destinationType > a.destinationType
            ? -1
            : 0
        );
        /*
          .sort((a, b) =>
            a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
          );
          */
      })
      .catch(function(error) {
        console.log(error);
        return error;
      }),

  getProductCategories: destId =>
    RequestHelper.get("taxonomy/categories", { destId })
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
        return error;
      }),

  getAttractions: (
    destId,
    sortOrder = "SEO_REVIEW_AVG_RATING_D",
    topX = "1-10"
  ) =>
    RequestHelper.post("taxonomy/attractions", { destId, sortOrder, topX })
      .then(function(response) {
        console.log(response);
        return response;
      })
      .catch(function(error) {
        console.log(error);
        return error;
      })
};
