import React, { Component } from "react";
import { ProductServices } from "../../api/ProductServices";
import RequestComponent from "../../../components/RequestComponent";

class FreeTextSearch extends Component {
  submitText = "freeTextSearch";
  parameters = [
    { paramKey: "destId", paramVal: "479" },
    { paramKey: "text", paramVal: "water" },
    { paramKey: "searchTypes", paramVal: "PRODUCT" },
    { paramKey: "currencyCode", paramVal: "USD" },
    { paramKey: "sortOrder", paramVal: "REVIEW_AVG_RATING_D" },
    { paramKey: "topX", paramVal: "1-5" }
  ];

  doRequest = paramStates => {
    return ProductServices.freeTextSearch(
      paramStates.destId,
      paramStates.text,
      paramStates.searchTypes,
      paramStates.currencyCode,
      paramStates.sortOrder,
      paramStates.topX
    ); //.then(res => res.data);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default FreeTextSearch;
