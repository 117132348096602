import React, { Component } from "react";
import { TaxonomyServices } from "../../api/TaxonomyServices";
import RequestComponent from "../../../components/RequestComponent";

class GetProductCategories extends Component {
  submitText = "getProductCategories";
  parameters = [{ paramKey: "destId", paramVal: "479" }];

  doRequest = paramStates => {
    return TaxonomyServices.getProductCategories(paramStates.destId); //.then(res => res.data);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default GetProductCategories;
