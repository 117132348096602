import React, { Component } from "react";
import { TaxonomyServices } from "../../api/TaxonomyServices";
import RequestComponent from "../../../components/RequestComponent";

class GetDestinations extends Component {
  submitText = "getDestinations";
  parameters = [];

  doRequest = paramStates => {
    return TaxonomyServices.getDestinations().then(res => ({ data: res }));
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default GetDestinations;
