import React, { Component } from "react";
import { ProductServices } from "../../api/ProductServices";
import RequestComponent from "../../../components/RequestComponent";

class SearchByProductCode extends Component {
  submitText = "searchByProductCode";
  parameters = [
    { paramKey: "currencyCode", paramVal: "USD" },
    { paramKey: "productCodes", paramVal: "3731LOUVRE" }
  ];

  doRequest = paramStates => {
    return ProductServices.searchByProductCode(
      [paramStates.productCodes],
      (paramStates.currencyCode = "USD")
    ); //.then(res => res.data);
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default SearchByProductCode;
