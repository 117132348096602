import React, { Component } from "react";
import SearchForProducts from "../ProductServiceComponent/SearchForProducts";
import SearchByProductCode from "../ProductServiceComponent/SearchByProductCode";
import FreeTextSearch from "../ProductServiceComponent/FreeTextSearch";

class ProductServiceComponent extends Component {
  render() {
    return (
      <>
        <SearchForProducts />
        <SearchByProductCode />
        <FreeTextSearch />
      </>
    );
  }
}

export default ProductServiceComponent;
