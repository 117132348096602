import React, { Component } from "react";
import { TaxonomyServices } from "../../api/TaxonomyServices";
import RequestComponent from "../../../components/RequestComponent";

class GetAttractions extends Component {
  submitText = "getAttractions";
  parameters = [
    { paramKey: "destId", paramVal: "479" },
    { paramKey: "sortOrder", paramVal: "SEO_REVIEW_AVG_RATING_D" },
    { paramKey: "topX", paramVal: "1-10" }
  ];

  doRequest = paramStates => {
    return TaxonomyServices.getAttractions(
      paramStates.destId,
      paramStates.sortOrder,
      paramStates.topX
    ).then(res => ({ data: res }));
  };

  render() {
    return (
      <RequestComponent
        parameters={this.parameters}
        submitText={this.submitText}
        doRequest={this.doRequest}
      />
    );
  }
}

export default GetAttractions;
