import React, { Component } from "react";
import GetDestinations from "../TaxonomyServicesComponent/GetDestinations";
import GetProductCategories from "../TaxonomyServicesComponent/GetProductCategories";
import GetAttractions from "../TaxonomyServicesComponent/GetAttractions";

class TaxonomyServicesComponent extends Component {
  render() {
    return (
      <>
        <GetDestinations />
        <GetProductCategories />
        <GetAttractions />
      </>
    );
  }
}

export default TaxonomyServicesComponent;
